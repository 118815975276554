<template>
  <div id="modal-template">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <button
            class="modal-close-button"
            @click="$store.dispatch('setModal', false)"
          >
            बन्द गर्नुहोस्
            <svg
              width="8"
              height="8"
              viewBox="0 0 8 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.79311 4.11426L7.78124 1.13087C7.87055 1.04156 7.92073 0.920426 7.92073 0.794117C7.92073 0.667809 7.87055 0.546674 7.78124 0.45736C7.69193 0.368047 7.57079 0.317871 7.44448 0.317871C7.31817 0.317871 7.19704 0.368047 7.10773 0.45736L4.12434 3.44549L1.14096 0.45736C1.05165 0.368047 0.930513 0.317871 0.804204 0.317871C0.677896 0.317871 0.556761 0.368047 0.467447 0.45736C0.378134 0.546674 0.327958 0.667809 0.327958 0.794117C0.327958 0.920426 0.378134 1.04156 0.467447 1.13087L3.45557 4.11426L0.467447 7.09764C0.422991 7.14173 0.387706 7.19419 0.363626 7.25199C0.339546 7.30979 0.327148 7.37178 0.327148 7.43439C0.327148 7.49701 0.339546 7.559 0.363626 7.6168C0.387706 7.6746 0.422991 7.72706 0.467447 7.77115C0.51154 7.81561 0.563999 7.85089 0.621797 7.87497C0.679596 7.89905 0.74159 7.91145 0.804204 7.91145C0.866818 7.91145 0.928813 7.89905 0.986611 7.87497C1.04441 7.85089 1.09687 7.81561 1.14096 7.77115L4.12434 4.78303L7.10773 7.77115C7.15182 7.81561 7.20428 7.85089 7.26208 7.87497C7.31987 7.89905 7.38187 7.91145 7.44448 7.91145C7.5071 7.91145 7.56909 7.89905 7.62689 7.87497C7.68469 7.85089 7.73715 7.81561 7.78124 7.77115C7.82569 7.72706 7.86098 7.6746 7.88506 7.6168C7.90914 7.559 7.92154 7.49701 7.92154 7.43439C7.92154 7.37178 7.90914 7.30979 7.88506 7.25199C7.86098 7.19419 7.82569 7.14173 7.78124 7.09764L4.79311 4.11426Z"
                fill="white"
              />
            </svg>
          </button>
          <div class="modal-header">
            {{ $store.state.modalData.header || "" }}
          </div>
          <div class="modal-body">
            {{ $store.state.modalData.body || "" }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.modal {
  &-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
  }
  &-wrapper {
    display: table-cell;
    padding: 0 20px;
    vertical-align: middle;
  }
  &-container {
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    border-radius: 20px;
    font-family: "Mukta", sans-serif;
    margin: 0px auto;
    max-height: 90vh;
    max-width: 600px;
    padding: 30px 60px;
    @media all and (max-width: 600px) {
      padding: 20px 30px;
    }
  }
  &-close-button {
    background: $primary;
    border-radius: 16px;
    color: $neutrals-white;
    cursor: pointer;
    font-size: 12px;
    font-family: "Mukta-SemiBold";
    float: right;
    margin-top: -70px;
    margin-right: -60px;
    padding: 7px 14px;
    @media all and (max-width: 600px) {
      margin-top: -60px;
      margin-right: -30px;
    }
    &:hover {
      @include bg-primary-gradient;
    }

    svg {
      margin-left: 6px;
    }
  }
  &-header h3 {
    margin-top: 0;
    color: #42b983;
  }
  &-body {
    margin: 20px 0;
  }
  &-enter-active,
  &-leave-active {
    transition: opacity 0.5s ease;
  }
  &-enter-from,
  &-leave-to {
    opacity: 0;
  }
}
</style>
